import "./App.css";
// import Header from "./partials/header";
// import Footer from "./partials/footer";
// import Hero from "./partials/hero";
// import Howitworks from "./partials/howitworks";
// import Services from "./partials/services";
// import Pricing from "./partials/pricing";
// import Contact from "./partials/contact";
import ComingSoon from "./partials/comingSoon";

function App() {
  return (
    <>
      {/* <Header /> */}

      <main id="main">
        <ComingSoon />
        {/* <Hero /> */}
        {/* <Howitworks />
        <Services />
        <Pricing />
        <Contact />  */}
      </main>
      {/* <Footer /> */}
    </>
  );
}

export default App;
