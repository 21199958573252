import React from "react";

function Hero() {
  return (
    <section
      id="hero"
      className="d-flex justify-content-center align-items-center"
      style={{ height: "80vh" }}
    >
      <div className="text-center">
        <div className="animate__animated animate__fadeInDown hero-banner-content">
          <a href="index.html">
            <img
              src="assets/img/fireash_logo_for_white_bg.png"
              alt=""
              className="img-fluid"
              height="250"
              width="250"
            />
          </a>
          <h1>Coming Soon</h1>
          <h4>Get ready! Something really cool is coming!</h4>
        </div>
      </div>
    </section>
  );
}

export default Hero;
